import React, { useState } from 'react'
// import firebase from 'firebase/app'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageSection from '../components/PageSection'
import BewerbungForm from '../components/BewerbungForm'
import { initialDataArtist, texts } from '../components/BewerbungFormDefinition'

import { firestore } from '../firebase'
import ApplicationAnnouncement from '../components/ApplicationAnnouncement'
import ApplicationOver from '../components/ApplicationOver'
import ApplicationMaintenance from '../components/ApplicationMaintenance'
import appConfig from '../appConfig'
import { calcPorto } from '../components/GalleryUtils'

function Bewerbung({ location }) {
  const [formLanguage, setFormLanguage] = useState('de')
  // const [queriedArtistIds, setQueriedArtistIds] = useState([])

  const onSelectChange = (evt) => {
    setFormLanguage(evt.target.value)
  }

  // React.useEffect(() => {
  //   getTodos()
  // }, [])

  // const getFiles = () => {
  //   const folderName = 'images'
  //   const storageRef = firebase.storage().ref(folderName)
  //   storageRef
  //     .listAll()
  //     .then((result) => {
  //       const z = result.items.map((file) =>
  //         file.location.path_.substring(folderName.length)
  //       )
  //       console.log(z)
  //       // result.items.slice(0, 10).forEach((file) => {
  //       //   console.log(file.location.path_.substring(folderName.length))
  //       // })
  //     })
  //     .catch((err) => console.error(err))
  // }

  // eslint-disable-next-line no-unused-vars
  const getTodos = async () => {
    try {
      const pureArtworks = await firestore
        .collection('artworks')
        // .where('artistId', '==', 'TvhFWGpewpWYuTVFFX8M')
        .where('fair', '==', '2024-1')
        // .where('deleted', '==', true)
        // .orderBy('id')
        .get()
      const fetchedArtworks = []
      pureArtworks.forEach((doc) => {
        const docData = doc.data()
        if (!docData.deleted) {
          fetchedArtworks.push(docData)
          // doc.ref.update({
          //   visible: true,
          //   porto: calcPorto(Number(docData.width), Number(docData.height)),
          //   portoActive: true,
          //   // deleted: firebase.firestore.FieldValue.delete(),
          // })
        }
      })
      console.log('fetchedArtworks', fetchedArtworks)

      const artistIdsWith20231 = [
        ...new Set(fetchedArtworks.map((artwork) => artwork.artistId)),
      ]
      console.log('artistIdsWith20231', artistIdsWith20231.sort())

      artistIdsWith20231.forEach((artistId) => {
        const toUpRef = firestore.collection('artists').doc(artistId)
        toUpRef.update({ join20231: true })
      })

      // const pureArtists = await firestore.collection('artists').get()
      // // const fetchedArtists = []
      // // pureArtists.forEach((doc) => {
      // //   fetchedArtists.push(doc.data())
      // // })
      // pureArtists.forEach((doc) => {
      //   // const docRef = doc.ref
      //   // const docData = doc.data()
      //   // setQueriedArtistIds((prev) => [...prev, doc.id])

      //   // if (docData.deleted) {
      //   //   doc.ref.delete()
      //   // }
      // })
    } catch (err) {
      console.error(err.message)
    }
  }

  // const updateArtworks = () => {
  //   queriedArtistIds.forEach((id) => {
  //     const docRef = firestore.collection('purchaseRequests').doc(id)
  //     docRef
  //       .update({
  //         wennrichMailSent: true,
  //         customerMailSent: true,
  //       })
  //       .then(function () {
  //         console.log('Document successfully updated!')
  //       })
  //       .catch(function (error) {
  //         // The document probably doesn't exist.
  //         console.error('Error updating document: ', error)
  //       })
  //   })
  // }

  return (
    <Layout location={location} showSlider={false}>
      <SEO keywords={[`kuboshow`, `bewerbung`]} title="Bewerbung" />
      <PageSection>
        <div className="row mb-2">
          <div className="col-12 col-md-8">
            <div className="d-flex w-100 justify-content-between">
              <h1 className="text-primary">{texts[formLanguage].bigHeading}</h1>
            </div>
          </div>
          <div className="col-auto">
            <select
              className="form-control"
              value={formLanguage}
              onChange={onSelectChange}
            >
              <option value="de">
                {/* <img src={deFlag} className="h-auto w-100" /> */}
                German
              </option>
              <option value="en">
                {/* <img src={ukFlag} className="h-auto w-100" /> */}
                English
              </option>
            </select>
          </div>
        </div>
        {/* <div className="row mb-4">
          <div className="col-12 col-md-8 ">
            <button
              type="button"
              className="btn btn-outline-primary p-2 text-dark"
              onClick={() => navigate('/login')}
            >
              {texts[formLanguage].forwardToLogin}
            </button>
          </div>
        </div> */}
        {appConfig.showApplicationMaintenance ? (
          <ApplicationMaintenance />
        ) : appConfig.showApplicationAnnouncement ? (
          <div
            className="w-100 d-flex pt-5 flex-column justify-content-center align-items-center text-center"
            style={{ marginBottom: '7rem' }}
          >
            <h1 className="text-primary">BEWERBUNG / APPLICATION</h1>
            <p>
              Die Online-Bewerbung steht ab 1. August 2024 wieder zur Verfügung.
            </p>
            <p>
              The online application will be available again from 1st of August 2024.{' '}
            </p>
          </div>
        ) : // <ApplicationAnnouncement
        //   headingDE={texts['de'].bigHeading}
        //   headingEN={texts['en'].bigHeading}
        // />
        appConfig.showApplicationOver ? (
          <ApplicationOver
            headingDE={texts['de'].bigHeading}
            headingEN={texts['en'].bigHeading}
          />
        ) : appConfig.showApplication ? (
          <BewerbungForm
            bewerbungInitialData={initialDataArtist}
            formLanguage={formLanguage}
          />
        ) : null}
      </PageSection>
    </Layout>
  )
}

export default Bewerbung
